import React from 'react';
import PulseLoader from 'react-spinners/PulseLoader';

import { RedButton, WhiteButton } from '../styles';

interface LoadingButtonProps {
  color: 'white' | 'red';
  text: string;
  isBusy: boolean;
  type?: 'submit' | 'button';
  onClick?: () => void;
}

const LoadingButton: React.FC<LoadingButtonProps> = ({
  color,
  text,
  isBusy,
  onClick = () => {},
  type = 'button'
}) => (
  <>
    {color === 'white' ? (
      <WhiteButton type={type} onClick={onClick} disabled={isBusy}>
        {isBusy ? <PulseLoader color="#444" loading size={8} /> : text}
      </WhiteButton>
    ) : (
      <RedButton type={type} onClick={onClick} disabled={isBusy}>
        {isBusy ? <PulseLoader color="#444" loading size={8} /> : text}
      </RedButton>
    )}
  </>
);

export default LoadingButton;
