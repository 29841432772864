import { ErrorMessage } from 'formik';
import React from 'react';

import * as S from './styles';

interface TextInputProps {
  name: string;
  type?: string;
  isInvalid: boolean;
  placeholder: string;
}

const TextInput: React.FC<TextInputProps> = ({
  name,
  type = 'text',
  isInvalid,
  placeholder
}) => (
  <S.InputContainer>
    <S.TextInput
      name={name}
      type={type}
      isInvalid={isInvalid}
      placeholder={placeholder}
    />
    {isInvalid && (
      <S.ErrorContainer>
        <ErrorMessage name={name} />
      </S.ErrorContainer>
    )}
  </S.InputContainer>
);

export default TextInput;
