import { Link } from 'gatsby';
import styled from 'styled-components';

import ArrowSVG from './icons/arrow';

export const Breadcrumbs = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 15px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    justify-content: left;
    padding: 0px;
  }
`;

export const GatsbyLink = styled(Link).attrs({
  activeClassName: 'active',
  partialyActive: true
})`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.gray[900]};
  border: 1px solid #eceef0;
  padding: 8px 15px;
  margin-right: 10px;
  white-space: wrap;
  text-transform: capitalize;
  text-decoration: none;

  @media (max-width: 768px) {
    margin-top: 5px;
    margin-right: 5px;
  }

  &::after {
    content: '';
    position: absolute;
    width: 15px;
    height: 100%;
    right: -20px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 9px;
    filter: grayscale(100%);
  }

  &.active,
  &:hover,
  &:focus {
    color: ${(props) => props.theme.colors.primary[900]};
  }

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const Arrow = styled(ArrowSVG).attrs({ alt: 'arrow' })`
  width: '15px';
  height: '12px';
  min-height: 'unset';
`;

export const Span = styled.span`
  display: inline-block;
  margin-left: 5px;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;
