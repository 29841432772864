import { motion } from 'framer-motion';
import React from 'react';

import { IAnimatedButton } from './types';

const variants = {
  initial: {
    scale: 1
  },
  animation: {
    scale: 1,
    transition: {
      duration: 1
    }
  },
  hover: {
    scale: 0.95,
    transition: {
      duration: 0.4
    }
  },
  tap: {
    scale: 0.9,
    transition: {
      duration: 0.2
    }
  }
};

const AnimatedButton = ({
  className,
  onClick,
  type,
  children
}: IAnimatedButton): JSX.Element => (
  <motion.button
    variants={variants}
    initial="initial"
    whileHover="hover"
    whileTap="tap"
    className={className}
    type={type}
    onClick={onClick}
  >
    {children}
  </motion.button>
);

export default AnimatedButton;
