/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react';
import { animated, useTransition } from 'react-spring';
import ResizeObserver from 'resize-observer-polyfill';

import DownArrow from '../icons/arrow.svg';
import * as S from './styles';

interface CollapsibleElementProps {
  children: any;
  title: string;
  defaultOpen?: boolean;
}

const checkElementHeight = (element: HTMLDivElement) =>
  element.style.transform === 'scale(0)';

const AppointmentButton: React.FC<CollapsibleElementProps> = ({
  children,
  title,
  defaultOpen
}): JSX.Element => {
  const [isVisible, setIsVisble] = useState(defaultOpen || false);
  const [isObserverAdded, setIsObserverAdded] = useState(false);
  const [height, setHeight] = useState(0);
  const ref = useRef<HTMLDivElement | null>(null);

  const handleResize = () => {
    if (isVisible) {
      setHeight(ref.current?.offsetHeight ?? 0);
    }
  };

  const resizeObserver = new ResizeObserver(() => handleResize());

  const transitions = useTransition(isVisible, null, {
    from: { position: 'absolute', opacity: 0, transform: 'scale(0)' },
    enter: { opacity: 1, transform: 'scale(1)' },
    leave: { opacity: 0, transform: 'scale(0)' }
  });

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (ref.current) {
      setHeight(ref.current.offsetHeight);
    }
  }, [isObserverAdded]);

  useEffect(() => {
    if (!isVisible && isObserverAdded && ref.current) {
      resizeObserver.unobserve(ref.current);
      setIsObserverAdded(false);
    }
  }, [isVisible, isObserverAdded]);

  useEffect(() => {
    if (ref.current && !isObserverAdded && checkElementHeight(ref.current)) {
      resizeObserver.observe(ref.current);
      setIsObserverAdded(true);
    }
  }, [isObserverAdded, setIsObserverAdded, resizeObserver]);

  return (
    <>
      <S.CollapsibleButton
        type="button"
        onClick={() => setIsVisble((prev) => !prev)}
      >
        <p>{title}</p>
        <S.Arrow src={DownArrow} alt="arrow" isVisible={isVisible} />
      </S.CollapsibleButton>
      <S.ElementContainer height={isVisible && height ? `${height}px` : '0px'}>
        {transitions.map(
          ({ item, key, props }) =>
            item && (
              <animated.div
                style={{
                  ...props,
                  transformOrigin: 'top left',
                  width: '100%',
                  cursor: 'default'
                }}
                ref={ref}
                key={key}
              >
                {children}
              </animated.div>
            )
        )}
      </S.ElementContainer>
    </>
  );
};

export default AppointmentButton;
