import { Link } from 'gatsby';
import styled from 'styled-components';

import {
  NavbarProps,
  HeaderProps,
  HamburgerProps,
  LogoProps
} from './styles.interfaces';

export const GatsbyLink = styled(Link).attrs({
  activeClassName: 'active',
  partialyActive: true
})`
  text-decoration: none;
  position: relative;
  transition: margin-left 250ms ease;
  font-size: 32px;
  line-height: 41.6px;

  @media (min-width: 1366px) {
    font-size: 14px;
  }

  &:hover {
    color: ${(props) => props.theme.colors.primary[500]} !important;
  }

  &.active {
    color: ${(props) => props.theme.colors.primary[100]};
    font-weight: 700;
  }
`;

export const CartLink = styled(Link)`
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  right: 4.5rem;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;

  @media (min-width: 1366px) {
    display: none;
  }
`;

export const Cart = styled.img`
  position: relative;
`;

export const Badge = styled.span`
  background: ${(props) => props.theme.colors.primary[100]};
  width: 20px;
  height: 20px;
  margin: 0px;
  border-radius: 50%;
  position: absolute;
  top: -13px;
  right: -8px;
  color: ${(props) => props.theme.colors.white};
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Hamburger = styled.div<HamburgerProps>`
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: absolute;
  right: 1.5rem;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
  transition: top 250ms ease;

  @media (min-width: 1366px) {
    display: none;
  }

  div {
    width: 100%;
    height: 0;
    border-radius: 0;
    border-bottom: 1px solid #000000;
    background: #000000;
    transform-origin: 1px;
    transition: all 250ms ease;

    &:first-child {
      transform: ${(props) => (props.isOpen ? 'rotate(45deg)' : 'rorate(0)')};
    }

    &:nth-child(2) {
      opacity: ${(props) => (props.isOpen ? '0' : '1')};
    }

    &:nth-child(3) {
      transform: ${(props) => (props.isOpen ? 'rotate(-45deg)' : 'rorate(0)')};
    }
  }
`;

export const DropdownContent = styled.ul`
  display: block;
  position: relative;
  background-color: ${(props) => props.theme.colors.white};
  z-index: 1;
  list-style: none;
  padding-left: 30px;
  width: auto;
  height: auto;

  @media (min-width: 1366px) {
    width: 100vw;
    height: 100vh;
    display: none;
    position: absolute;
    margin-top: -5px;
    min-width: 200px;
    width: auto;
    height: auto;
    padding-left: unset;
    background-color: ${(props) => props.theme.colors.white};
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    border: 1px solid ${(props) => props.theme.colors.black};
  }

  li {
    &:last-child {
      padding: 0;
    }
  }

  ${GatsbyLink} {
    display: block;
    &:hover {
      background-color: ${(props) => props.theme.colors.gray[100]};
      color: ${(props) => props.theme.colors.primary[500]};
    }
    &:active {
      color: ${(props) => props.theme.colors.primary[100]};
    }
  }
`;
export const Dropdown = styled.div`
  position: relative;
  display: block;

  &:hover ${DropdownContent} {
    display: block;
  }
`;
export const Header = styled.header<HeaderProps>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
  padding: 0.5rem 1.5rem;
  border-bottom: 1px solid #000000;
  height: ${(props) => (props.smaller ? 72 : 120)}px;
  background: white;
  transition: height 250ms ease;
  display: flex;
  align-items: center;
`;

export const InnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: ${(props) => props.theme.layout.maxHeaderWidth};
  padding: 0 ${(props) => props.theme.layout.paddingX};
  width: 100%;
  margin: 0 auto;
`;

export const Logo = styled.div<LogoProps>`
  display: ${(props) => (props.isMobile ? 'flex' : 'none')};
  width: auto;
  cursor: pointer;

  @media (min-width: 1366px) {
    display: ${(props) => (props.isMobile ? 'none' : 'flex')};
  }

  img {
    width: 146px;
  }
`;

export const Navbar = styled.nav<NavbarProps>`
  position: absolute;
  top: 72px;
  right: 0;
  width: 100vw;
  height: 100vh;
  transform: ${(props) =>
    props.isOpen ? 'translateX(0)' : 'translateX(100%)'};
  background: ${(props) => props.theme.colors.white};
  transition: transform 250ms ease;
  overflow-y: scroll;

  @media (min-width: 1366px) {
    top: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: relative;
    height: 100%;
    transform: none;
    background: transparent;
    overflow-y: unset;
  }

  ${GatsbyLink} {
    color: ${(props) => props.theme.colors.black};
  }
`;

export const LinksList = styled.ul`
  width: 100%;
  height: auto;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;

  &:first-child {
    justify-content: flex-start;
  }

  @media (max-width: 1366px) {
    li {
      padding: 16px 0;
    }

    &:first-child {
      margin-top: 1rem;
    }

    &:last-child {
      li {
        &:last-child {
          display: none;
        }
      }
    }
  }

  @media (min-width: 1366px) {
    flex-direction: row;
    margin-top: 0;
    align-items: center;

    &:last-child {
      li {
        &:last-child {
          display: block;
        }
      }
    }
  }

  ${GatsbyLink} {
    padding: 0.5rem 1.25rem;
  }

  .dropdownbtn:hover ~ ${Dropdown} {
    ${DropdownContent} {
      display: block;
    }
  }
`;
