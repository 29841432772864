import FacebookIcon from './icons/facebook.svg';
import InstagramIcon from './icons/instagram.svg';
import LinkedInIcon from './icons/linkedin.svg';

export const links = [
  {
    url: '/nasza-historia',
    label: 'O nas'
  },
  {
    url: '/oferta-dla-firm',
    label: 'Oferta dla firm'
  },
  {
    url: '/kontakt',
    label: 'Kontakt'
  },
  {
    url: '/aplikacja',
    label: 'Aplikacja'
  },
  {
    url: '/',
    label: 'Regulamin sklepu'
  },
  {
    url: '/',
    label: 'Polityka prywatności'
  }
];

export const socialLinks = [
  {
    image: FacebookIcon,
    alt: 'facebook-icon',
    link: 'https://www.facebook.com/deskwise1/'
  },
  {
    image: InstagramIcon,
    alt: 'instagram-icon',
    link: 'https://www.instagram.com/deskwise/?hl=en'
  },
  {
    image: LinkedInIcon,
    alt: 'linkedin-icon',
    link: 'https://www.linkedin.com/company/deskwisee'
  }
];
