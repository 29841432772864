import { Field, useField, ErrorMessage } from 'formik';
import React from 'react';

import Checked from './checked.svg';
import * as S from './styles';

interface CheckboxProps {
  name: string;
  isInvalid?: boolean;
  setFieldValue: (name: string, value: any) => void;
  children: any;
}

const Checkbox: React.FC<CheckboxProps> = ({
  setFieldValue,
  name,
  isInvalid = false,
  children
}): JSX.Element => {
  const [field] = useField({
    name,
    type: 'checkbox'
  });

  const { checked } = field;

  const toggleCheck = () => {
    setFieldValue(name, !checked);
  };

  return (
    <>
      <S.CheckboxContainer>
        <Field name={name}>
          {() => (
            <label htmlFor={name}>
              <S.Container>
                <S.HiddenCheckbox
                  checked={checked}
                  onClick={toggleCheck}
                  id={name}
                />
                <S.StyledCheckbox checked={checked} error={isInvalid}>
                  <S.IconImage src={Checked} alt="checkedIcon" />
                </S.StyledCheckbox>
              </S.Container>
            </label>
          )}
        </Field>
        <S.LabelContainer>{children}</S.LabelContainer>
      </S.CheckboxContainer>
      {isInvalid && (
        <S.ErrorContainer>
          <ErrorMessage name="privacy" />
        </S.ErrorContainer>
      )}
    </>
  );
};

export default Checkbox;
