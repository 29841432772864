/* eslint-disable react/no-danger */

/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable react/prop-types */

/* eslint-disable @typescript-eslint/indent */

/* eslint-disable react/jsx-indent-props */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useRef, useEffect, useState } from 'react';

const withHTML = <T,>(HtmlElementAsString = 'div') =>
  React.forwardRef<React.Ref<T>, any>(({ children, ...props }, ref) => (
    <HtmlElementAsString
      dangerouslySetInnerHTML={{ __html: children }}
      {...props}
      ref={ref}
    />
  ));

const HTMLAsChildren = (__html: string) => {
  const [text, setter] = useState('');
  const component = useRef<HTMLElement | null>(null);
  const Elem = () => (
    <span ref={component} dangerouslySetInnerHTML={{ __html }} />
  );

  useEffect(() => {
    if (component.current) {
      setter(component.current.innerText);
    }
  }, [component]);

  return [component, text];
};

export { HTMLAsChildren };

export default withHTML;
