import { Field } from 'formik';
import styled from 'styled-components';

interface TextareaProps {
  isInvalid: boolean;
}

export const InputContainer = styled.div`
  margin-bottom: 24px;
`;

export const StyledTextarea = styled(Field)<TextareaProps>`
  border: 1px solid ${(props) => (props.isInvalid ? '#fc4e24' : '#BDBDC7')};
  padding: 12px;
  width: 100%;
  font-family: ${(props) => props.theme.fonts.family.main};
  color: ${(props) => props.theme.colors.black};
  font-weight: 600;
  font-size: 16px;
  height: 125px;
  resize: none;

  &::placeholder {
    color: ${(props) => props.theme.colors.gray![500]};
  }
`;

export const ErrorContainer = styled.p`
  margin-top: 8px;
  color: ${(props) => props.theme.colors.primary[300]};
  text-align: left;
`;
