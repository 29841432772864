import React from 'react';

import * as S from './styles';

interface HamburgerProps {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  smaller: boolean;
}

const Hamburger: React.FC<HamburgerProps> = ({
  isOpen,
  setOpen,
  smaller
}): JSX.Element => (
  <S.Hamburger
    isOpen={isOpen}
    onClick={() => setOpen((prevState) => !prevState)}
    smaller={smaller}
  >
    <div />
    <div />
    <div />
  </S.Hamburger>
);

export default Hamburger;
