import styled from 'styled-components';

export const Container = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const StyledCheckbox = styled.div<any>`
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 8px;
  background: ${(props) => (props.checked ? '#0075ff' : 'white')};
  border: ${(props) => (props.checked ? 'none' : '1px solid gray')};
  border-radius: 5px;
  transition: all 200ms;
  position: relative;
  box-shadow: ${(props) => (props.error ? '0px 0px 2px 2px #fc4e24' : 'none')};
`;

export const IconImage = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ErrorContainer = styled.p`
  margin-top: 8px;
  color: ${(props) => props.theme.colors.primary[300]};
  text-align: left;
`;

export const LabelContainer = styled.div`
  flex-grow: 1;
  text-align: left;
`;
