import { AnimatedLink } from '@components/shared';
import React, { useState, useEffect } from 'react';

import * as S from './styles';

interface IBreadcrumbs {
  label: string;
  url: string;
}

const Breadcrumbs: React.FC<{ elements: IBreadcrumbs[] }> = ({
  elements = []
}): JSX.Element => {
  const [state, setter] = useState<IBreadcrumbs[]>([
    { label: 'Główna', url: '/' }
  ]);

  useEffect(() => {
    setter((prevState) => [...prevState, ...elements]);
  }, []);

  return (
    <S.Breadcrumbs>
      {state.map((elm) => (
        <>
          <AnimatedLink key={elm.url}>
            <S.GatsbyLink to={elm.url}>
              <S.Arrow />
              <S.Span
                dangerouslySetInnerHTML={{
                  __html: elm.label
                }}
              />
            </S.GatsbyLink>
          </AnimatedLink>
        </>
      ))}
    </S.Breadcrumbs>
  );
};

export default Breadcrumbs;
