import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  layout: {
    maxWidth: '1128px',
    maxHeaderWidth: '1440px',
    paddingX: '0'
  },
  colors: {
    primary: {
      100: '#ED3103',
      300: '#FC4E24',
      500: '#BD2703'
    },
    secondary: '#FFD700',
    gray: {
      100: '#F7F7F8',
      300: '#F2F2F4',
      500: '#BDBDC7'
    },
    white: '#fff',
    black: '#000'
  },
  fonts: {
    family: {
      main: 'Helvetica LT W04 Light1489428, sans-serif',
      secondary: 'Helvetica LT W04 Light1489428, sans-serif'
    },
    size: {
      h1: '2.625rem',
      h2: '2.625rem',
      h3: '1.5rem',
      h4: '1.25rem',
      h5: '1rem',
      p: '16px',
      small: '0.875rem'
    }
  },
  shadows: {
    light: '0px 5px 10px 0px rgba(221, 226, 243, 0.2)'
  }
};

export default theme;
