import React from 'react';

const MessengerChat: React.FC = () => (
  <>
    <div id="fb-root" />
    <div id="fb-customer-chat" className="fb-customerchat" />
  </>
);

export default MessengerChat;
