/* eslint-disable @typescript-eslint/no-unsafe-return */

/* eslint-disable @typescript-eslint/no-floating-promises */

/* eslint-disable react/no-array-index-key */
import { IProduct } from '@standardTypes';
// eslint-disable-next-line import/no-extraneous-dependencies
import { navigate } from 'gatsby-link';
import React, { useState, useEffect, useCallback } from 'react';

import scrollTop from '../../logic/scrollTop';
import SmallArrowSVG from './icons/SmallArrowSVG';

interface IPagination {
  data: any[];
  RenderComponent: React.FC<any>;
  pageLimit: number;
  dataLimit: number;
  pageParam: string;
  changeDataChanged: () => void;
  isDataChanged: boolean;
}

const getParam = (param: string) => {
  const regex = /[0-9]{1,}/;
  return regex.exec(param);
};

const Pagination: React.FC<IPagination> = ({
  data,
  RenderComponent,
  pageLimit,
  dataLimit,
  pageParam,
  changeDataChanged,
  isDataChanged
}): JSX.Element => {
  const [pages] = useState(Math.ceil(data.length / dataLimit));
  const [currentPage, setCurrentPage] = useState(1);
  const [someData, setSomeData] = useState<IProduct[]>([]);

  useEffect(() => {
    const page = getParam(pageParam);
    page && setCurrentPage(Number(page));
  }, []);

  const goToNextPage = () => {
    setCurrentPage((prevPage) => {
      navigate(`?page=${prevPage + 1}`);
      return prevPage + 1;
    });
    scrollTop();
  };

  const goToPreviousPage = () => {
    setCurrentPage((prevPage) => {
      navigate(`?page=${prevPage - 1}`);
      return prevPage - 1;
    });
    scrollTop();
  };

  const getPaginatedDate = useCallback(() => {
    const startIndex = currentPage * dataLimit - dataLimit;
    const endIndex = startIndex + dataLimit;
    return data.slice(startIndex, endIndex);
  }, [data, currentPage, dataLimit]);

  useEffect(() => {
    if (isDataChanged) {
      setSomeData(getPaginatedDate());
      changeDataChanged();
    }
  }, [data, getPaginatedDate, isDataChanged, changeDataChanged]);

  const changePage = (event: React.MouseEvent<HTMLButtonElement>) => {
    const { innerText } = event.target as HTMLButtonElement;
    const pageNumber = Number(innerText);
    setCurrentPage(pageNumber);
    scrollTop();
    navigate(`?page=${pageNumber}`);
  };

  const getNextChunk = (first = 0): number[] =>
    new Array(first ? pageLimit + first : pageLimit)
      .fill(0)
      .map((_, i) => currentPage + i + 1)
      .filter((el) => el <= pages);

  const getPrevChunk = (last = 0): number[] =>
    new Array(last ? pageLimit + last : pageLimit)
      .fill(0)
      .map((_, i) => currentPage - i - 1)
      .filter((el) => el > 0)
      .reverse();

  const getPaginationGroup = (): number[] => {
    let pagesChunk = new Array(pageLimit * 2 + 1).fill(0);

    if (currentPage === 1) {
      pagesChunk = [currentPage, ...getNextChunk(pageLimit)];
    }

    if (currentPage === pages) {
      pagesChunk = [...getPrevChunk(pageLimit), currentPage];
    }

    if (currentPage !== 1 && currentPage !== pages) {
      pagesChunk = [...getPrevChunk(), currentPage, ...getNextChunk()];
    }

    return pagesChunk as number[];
  };

  return (
    <div>
      <div className="dataContainer">
        {someData &&
          someData.map((el, i) => <RenderComponent key={i} data={el} />)}
      </div>

      <div className="pagination">
        <button
          type="button"
          onClick={goToPreviousPage}
          disabled={currentPage === 1}
          className="previous"
        >
          <SmallArrowSVG />
        </button>

        <div className="pagesContainer">
          {getPaginationGroup().map((el) => (
            <button
              type="button"
              key={el}
              onClick={changePage}
              className={el === currentPage ? 'page active' : 'page'}
            >
              {el}
            </button>
          ))}
        </div>

        <button
          type="button"
          onClick={goToNextPage}
          className="next"
          disabled={currentPage === pages}
        >
          <SmallArrowSVG />
        </button>
      </div>
    </div>
  );
};

export default Pagination;
