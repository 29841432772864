import React, { useState, useRef, useEffect } from 'react';
import { animated, useTransition } from 'react-spring';

import DownArrow from '../icons/arrow.svg';
import * as S from './styles';

interface CollapsibleElementProps {
  children: React.ReactNode;
  title: string;
}

const CollapsibleElement: React.FC<CollapsibleElementProps> = ({
  children,
  title
}) => {
  const [isVisible, setIsVisble] = useState(false);
  const [height, setHeight] = useState(0);

  const ref = useRef<HTMLDivElement | null>(null);

  const transitions = useTransition(isVisible, null, {
    from: { position: 'absolute', opacity: 0, transform: 'scale(0)' },
    enter: { opacity: 1, transform: 'scale(1)' },
    leave: { opacity: 0, transform: 'scale(0)' }
  });

  useEffect(() => {
    const handleResize = () => {
      setHeight(ref.current?.offsetHeight ?? 0);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (ref.current) {
      setHeight(ref.current.offsetHeight);
    }
  }, [isVisible]);

  return (
    <S.ElementContainer height={isVisible && height ? `${height}px` : '26px'}>
      <S.CollapsibleHeading onClick={() => setIsVisble((prev) => !prev)}>
        <h4>{title}</h4>
        <S.ArrowImage src={DownArrow} alt="arrow" isVisible={isVisible} />
      </S.CollapsibleHeading>
      <div>
        {transitions.map(
          ({ item, key, props }) =>
            item && (
              <animated.div
                style={{
                  ...props,
                  transformOrigin: 'top left',
                  width: '100%'
                }}
                ref={ref}
                key={key}
              >
                {children}
              </animated.div>
            )
        )}
      </div>
    </S.ElementContainer>
  );
};

export default CollapsibleElement;
