import React from 'react';

import * as S from './styles';

interface PageHeaderProps {
  title: string;
  description?: string;
  isTransparent?: boolean;
}

const PageHeader: React.FC<PageHeaderProps> = ({
  title = 'Nazwa sekcji',
  description,
  isTransparent
}): JSX.Element => (
  <S.Section isTransparent={isTransparent}>
    <S.ProductsHero>
      <S.Header isCenter={!!description}>{title}</S.Header>
      {description && <S.Text>{description}</S.Text>}
    </S.ProductsHero>
  </S.Section>
);
export default PageHeader;
