import styled from 'styled-components';

interface IHeader {
  isCenter: boolean;
  isTransparent: boolean;
}

const Section = styled.section<IHeader>`
  border-bottom: 1px solid ${(props) => (props.isTransparent ? 'transparent' : props.theme.colors.black)};
  background-color: ${(props) => (props.isTransparent ? 'transparent' : props.theme.colors.gray[300])};
  padding: 72px 24px;

  @media (min-width: ${(props) => props.theme.layout.maxWidth}) {
    padding-inline: 0;
  }
`;

const ProductsHero = styled.div`
  max-width: ${(props) => props.theme.layout.maxWidth};
  width: 100%;
  margin: 0 auto;

  @media (min-width: ${(props) => props.theme.layout.maxWidth}) {
    display: flex;
  }
`;

const Header = styled.h1<IHeader>`
  max-width: 456px;
  width: 100%;
  /* margin-bottom: 64px; */

  text-align: ${(props) => (props.isCenter ? 'left' : 'center')};

  @media (min-width: ${(props) => props.theme.layout.maxWidth}) {
    margin-bottom: 0;
    margin-inline: auto;
  }
`;

const Text = styled.p`
  margin-top: 32px;
`;

export { Section, Header, Text, ProductsHero };
