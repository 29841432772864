import React from 'react';

import LogoImage from '../../../images/logo.svg';
import * as S from './styles';

interface LogoProps {
  isMobile: boolean;
}

const Logo: React.FC<LogoProps> = ({ isMobile }): JSX.Element => (
  <S.Logo isMobile={isMobile}>
    <img src={LogoImage} alt="logo" />
  </S.Logo>
);

export default Logo;
