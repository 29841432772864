/* eslint-disable */
import { CartCtx, useContextState } from '@components/contexted';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

import Hamburger from './Hamburger';
import CartImage from './images/Cart.png';
import Logo from './logo';
import * as S from './styles';
import { Isum } from './types';
import useScrollHeight from './useScrollHeight';

const Header: React.FC = (): JSX.Element => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const { height } = useScrollHeight();
  const { sum }: { sum: Isum } = useContextState(CartCtx, ['sum']);
  const count = sum.qty;

  const links = [
    {
      name: 'Produkty',
      url: '/kategoria/produkty/',
      submenu: [
        {
          name: 'Biurka',
          url: '/kategoria/biurka/'
        },
        {
          name: 'Fotele',
          url: '/kategoria/fotele/'
        }
      ]
    },
    { name: 'Zastosowania', url: '/zastosowania' },
    { name: 'Wiedza', url: '/wiedza' },
    { name: 'Nasza historia', url: '/nasza-historia' }
  ];

  const userLinks = [
    { name: 'Dla firm', url: '/oferta-dla-firm/' },
    { name: 'Konto', url: '/konto/' },
    { name: 'Zapisane', url: '/zapisane/' },
    { name: `Koszyk (${count})`, url: '/koszyk/' }
  ];
  return (
    <S.Header smaller>
      <Helmet>
        <body className={isOpen ? 'blocked' : ''} />
      </Helmet>

      <S.GatsbyLink to="/">
        <Logo isMobile />
      </S.GatsbyLink>

      <S.CartLink to="/koszyk">
        <S.Cart src={CartImage} />
        <S.Badge>{count}</S.Badge>
      </S.CartLink>
      <Hamburger isOpen={isOpen} setOpen={setOpen} smaller={height > 135} />

      <S.InnerWrapper>
        <S.Navbar isOpen={isOpen}>
          <S.LinksList>
            {links?.map((link) => (
              <li key={link.url}>
                <S.GatsbyLink
                  to={link.url}
                  className={link.submenu ? 'dropdownbtn' : null}
                >
                  {link.name}
                </S.GatsbyLink>
                {link?.submenu ? (
                  <S.Dropdown>
                    <S.DropdownContent>
                      {link.submenu?.map((sublink) => (
                        <li key={sublink.name}>
                          <S.GatsbyLink to={sublink.url}>
                            {sublink.name}
                          </S.GatsbyLink>
                        </li>
                      ))}
                    </S.DropdownContent>
                  </S.Dropdown>
                ) : null}
              </li>
            ))}
          </S.LinksList>

          <S.GatsbyLink to="/">
            <Logo isMobile={false} />
          </S.GatsbyLink>

          <S.LinksList>
            {userLinks.map((link) => (
              <li key={link.url}>
                <S.GatsbyLink to={link.url}>{link.name}</S.GatsbyLink>
              </li>
            ))}
          </S.LinksList>
        </S.Navbar>
      </S.InnerWrapper>
    </S.Header>
  );
};

export default Header;
