import styled from 'styled-components';

export const Layout = styled.div`
  overflow: hidden;
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Main = styled.main`
  margin: 52px auto 0px auto;
  padding: 0 ${(props) => props.theme.layout.paddingX};
  width: 100%;
`;
