/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable consistent-return */
/* eslint-disable no-confusing-arrow */
/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/indent */
/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-restricted-syntax */
const getSortedSrcSet = (
  srcSet: string,
): Array<string | Array<number>> | undefined => {
  const regexConst = new RegExp('[0-9]{1,4}x[0-9]{1,4}', 'g');
  if (srcSet) {
    const sortedArray = srcSet
      .split(', ')
      .map((el) => el.split(' '))
      .sort((a, b) => Number(a[1].replace('w', '')) > Number(b[1].replace('w', '')) ? 1 : -1);

    const result = sortedArray.map((el: any) => {
      const sizes = el[0].match(regexConst);
      if (sizes) {
        const [dims] = sizes;
        el.push(dims.split('x').map(Number));
      } else {
        el.push(null);
      }
      return el;
    });
    return result;
  }
};

const getCssSizes = (sortedSizes: Array<string>): string => sortedSizes
    .map(
      (curr) => `(max-width: ${curr[1].replace('w', 'px')}) ${curr[1].replace(
          'w',
          'px',
        )}) ${curr[1].replace('w', 'px')}`,
      [],
    )
    .join(', ');

const getClosestValue = (
  array: Array<string | Array<number>>,
  value: string,
) => {
  const width = Number(value.replace('w', ''));
  const diffs: Array<number> = [];
  for (const [_, __, sizes] of array) {
    if (sizes) {
      const diff = Math.abs(sizes[0] - width);
      diffs.push(diff);
    }
  }
  const idx = diffs.indexOf(Math.min(...diffs));
  return array[idx];
};

export { getSortedSrcSet, getCssSizes, getClosestValue };
