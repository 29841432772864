/* eslint-disable @typescript-eslint/no-floating-promises */
import { motion, useAnimation } from 'framer-motion';
import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import { IAnimatedSection } from './types';

const AnimatedSection: React.FC<IAnimatedSection> = ({
  className,
  children,
  ...rest
}): JSX.Element => {
  const { ref, inView } = useInView();
  const animation = useAnimation();

  useEffect(() => {
    if (inView) {
      animation.start({
        x: 0,
        opacity: 1,
        transition: {
          duration: 0.5,
          type: 'spring'
        }
      });
    }
  }, [inView]);
  return (
    <motion.section
      {...rest}
      ref={ref}
      className={className}
      animate={animation}
      initial={{ x: '-50vw' }}
    >
      {children}
    </motion.section>
  );
};

export default AnimatedSection;
