import { Link } from 'gatsby';
import React from 'react';

import { links, socialLinks } from './data';
import Logo from './icons/logo.png';
import * as S from './styles';

const Footer: React.FC = (): JSX.Element => (
  <S.Footer>
    <S.Information>
      <img src={Logo} alt="logo" />
      <p>
        Faucibus ut mauris urna libero, nulla. Vel quisque facilisis id pretium
        quam suspendisse elit. Mi consectetur viverra in commodo. Quis ac arcu
        vel nec ultricies morbi fusce nisl.
      </p>
    </S.Information>
    <S.LinksContainer>
      {links.map(({ url, label }) => (
        <S.StyledLink key={label} to={url}>
          <p>{label}</p>
        </S.StyledLink>
      ))}
      <S.SocialContainer>
        {socialLinks.map(({ image, alt, link }) => (
          <Link key={alt} to={link} target="_blank">
            <img src={image} alt={alt} />
          </Link>
        ))}
      </S.SocialContainer>
    </S.LinksContainer>
  </S.Footer>
);

export default Footer;
