import { Head, Footer, Header, MessengerChat } from '@components/layout';
import { useLocation } from '@reach/router';
import { ILayout } from '@standardTypes';
import React from 'react';
import Modal from 'react-modal';
import { ThemeProvider } from 'styled-components';

import { GlobalStyles } from '../../../styles/global';
import theme from '../../../styles/theme';
import * as S from './styles';

Modal.setAppElement('#___gatsby');

const Layout: React.FC<ILayout> = ({
  children,
  siteMetaData,
  seo,
  // imBusy,
  translationUrl,
  headerDisabled
}): JSX.Element => {
  let defaultTranslation = {
    pl: '/pl',
    en: '/en'
  };
  if (translationUrl) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    defaultTranslation = translationUrl;
  }

  const location = useLocation();

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <S.Layout>
        <Head seo={seo} siteMetaData={siteMetaData} location={location} />
        {!headerDisabled && <Header />}

        <S.Main>{children}</S.Main>
        <Footer />
      </S.Layout>
      <MessengerChat />
    </ThemeProvider>
  );
};

export default Layout;
