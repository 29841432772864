import styled from 'styled-components';

interface ElementContainerProps {
  height: string;
}

export const ElementContainer = styled.div<ElementContainerProps>`
  border-bottom: 1px solid black;
  padding: 26px 0px ${(props) => props.height} 0px;
  transition: padding 450ms ease;
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;

  &:first-of-type {
    border-top: 1px solid black;
  }
`;

export const CollapsibleHeading = styled.div`
  display: flex;
  justify-content: space-between;
`;

interface ArrowImageProps {
  isVisible: boolean;
}

export const ArrowImage = styled.img<ArrowImageProps>`
  transition: transform 450ms;
  transform: ${(props) =>
    props.isVisible ? 'rotate(180deg)' : 'rotate(0deg)'};
`;
