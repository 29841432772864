import { createGlobalStyle } from 'styled-components';

import HelveticaOne from './fonts/5e04826f-5440-42ee-82bb-b212cb16bccc.woff2';
import HelveticaTwo from './fonts/e0eda329-a6eb-46ac-8f15-e1c54de80856.woff';

// import HKGroteskRegular from './fonts/hk-grotesk.regular.woff';
// import HKGroteskSemibold from './fonts/hk-grotesk.semibold.woff';

export const GlobalStyles = createGlobalStyle`
    /* @font-face {
        font-family: "HK Grotesk";
        src: local("HK Grotesk"), local("HKGrotesk"), url(${HKGroteskRegular}) format('woff'), url(${HKGroteskSemibold}) format('woff');
    } */

    @font-face{
        font-family:"Helvetica LT W04 Light1489428";
        src: local(${HelveticaOne}), url(${HelveticaOne}) format("woff2"), local(${HelveticaTwo}), url(${HelveticaTwo}) format("woff");
    }

    *,
    *::before,
    *::after {
        box-sizing: border-box;
        padding: 0;
        margin: 0;
        outline: none;
    }

    html {
        scroll-behavior: smooth;
        font-size: ${(props) => props.theme.fonts.size.p};
    }

    body {
        font-family: ${({ theme }) => theme.fonts.family.main};
        overflow: hidden;
        overflow-y: scroll;

        &.blocked {
            overflow-y: hidden
        }

        h1{
            font-size: ${(props) => props.theme.fonts.size.h1};
            font-family: ${(props) => props.theme.fonts.family.secondary};
            line-height: 57.96px;
        }

        h2{
            font-size: ${(props) => props.theme.fonts.size.h2};
            font-family: ${(props) => props.theme.fonts.family.secondary};
            line-height: 57.96px;
        }

        h3{
            font-size: ${(props) => props.theme.fonts.size.h3};
            line-height: 31.27px;
            font-family: ${(props) => props.theme.fonts.family.main};
        }

        h4{
            font-size: ${(props) => props.theme.fonts.size.h4};
            line-height: 26.06px;
            font-family: ${(props) => props.theme.fonts.family.main};
            font-weight: 600;
        }

        h5 {
            font-size: ${(props) => props.theme.fonts.size.h5};
            line-height: 20.85px;
            font-family: ${(props) => props.theme.fonts.family.main};
        }

        p{
            font-size: ${(props) => props.theme.fonts.size.p};
            line-height: 20.85px;
            font-family: ${(props) => props.theme.fonts.family.main};
            font-weight: 400;
        }

        small{
            font-size: ${(props) => props.theme.fonts.size.small};
            font-family: ${(props) => props.theme.fonts.family.main};
        }

        button,a,span{
            font-family: ${(props) => props.theme.fonts.family.main};
        }
    }

    #___gatsby {
        display: block;
        margin: auto;
    }

    .loader {
    position: fixed;
    background: $white;
    color: $main_navy;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000 * 1000;
    display: flex;
    justify-content: center;
    align-items: center; 
}
`;
