import { motion } from 'framer-motion';
import React from 'react';

import { IAnimatedLink } from './types';

const variants = {
  initial: {
    scale: 1
  },
  animation: {
    scale: 1,
    transition: {
      duration: 0.4
    }
  },
  hover: {
    scale: 0.95,
    transition: {
      duration: 0.4
    }
  },
  tap: {
    scale: 0.9,
    transition: {
      duration: 0.2
    }
  }
};

const AnimatedLink = ({ children }: IAnimatedLink): JSX.Element => (
  <motion.div
    variants={variants}
    initial="initial"
    animate="animation"
    whileHover="hover"
    whileTap="tap"
  >
    {children}
  </motion.div>
);

export default AnimatedLink;
