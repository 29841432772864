import { Link } from 'gatsby';
import styled from 'styled-components';

export const Footer = styled.footer`
  background: ${(props) => props.theme.colors.gray!['300']};
  padding: 72px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: 1128px) {
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    padding: 72px 156px 124px;
  }
`;

export const Information = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 64px;

  @media (min-width: 1128px) {
    padding: 0px;
    width: 456px;
    margin-bottom: 0px;
  }

  p {
    margin-top: 35px;
    font-size: 16px;
    line-height: 21px;
    font-weight: normal;
    font-size: 16px;
  }
`;

export const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  @media (min-width: 1128px) {
    align-items: flex-start;
    width: 341px;
    height: 117px;
    order: 4;
    flex-wrap: wrap;

    a {
      width: 49%;
    }
  }
`;

export const SocialContainer = styled.div`
  margin-top: 35px;
  width: 104px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: black;

  p {
    font-weight: normal;
    font-size: 16px;
  }

  &:not(:first-of-type) {
    margin-top: 10px;
  }

  @media (min-width: 1128px) {
    &:nth-of-type(5) {
      margin-top: 0px;
    }
  }
`;
