import styled, { css } from 'styled-components';

const mixin = css`
  width: 100%;
  padding: 12px 32px;
  font-family: ${(props) => props.theme.fonts.family.main};
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
`;

export const RedButton = styled.button`
  color: ${(props) => props.theme.colors.white};
  background: ${(props) => props.theme.colors.primary[300]};
  border: none;
  ${mixin}

  &:hover {
    background: ${(props) => props.theme.colors.primary[500]};
  }

  &:active {
    background: ${(props) => props.theme.colors.primary[100]};
  }

  &:disabled,
  &[disabled] {
    background-color: #f2f2f4;
  }
`;

export const WhiteButton = styled.button`
  color: ${(props) => props.theme.colors.black};
  border: 1px solid ${(props) => props.theme.colors.black};
  background-color: transparent;
  ${mixin}

  &:hover {
    background-color: ${(props) => props.theme.colors.primary[500]};
    color: ${(props) => props.theme.colors.white};
  }

  &:active {
    background-color: ${(props) => props.theme.colors.primary[100]};
    color: ${(props) => props.theme.colors.white};
  }

  &:disabled,
  &[disabled] {
    background-color: #f2f2f4;
    color: #bdbdc7;
  }
`;
