/* eslint-disable react/no-unused-state */

/* eslint-disable react/state-in-constructor */

/* eslint-disable react/static-property-placement */

/* eslint-disable prefer-destructuring */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';

import { getSortedSrcSet, getClosestValue } from './logic';

const placeholder =
  'https://via.placeholder.com/300x300.png?text=Nie+ma+obrazka';

interface IProps {
  altText: string;
  style?: Record<string, any>;
}

interface IState {
  sortedSrcSet: Array<string | Array<number>>;
  sourceUrl: string;
}

class ResizedImage extends React.Component<IProps, IState> {
  state = {
    sortedSrcSet: [],
    sourceUrl: ''
  };

  static defaultProps = {
    size: '150w',
    altText: 'Image on page'
  };

  static getDerivedStateFromProps(
    props: { srcSet: any; sourceUrl?: any; size?: any },
    current_state: { sourceUrl: any }
  ) {
    if (
      typeof props.srcSet !== 'string' ||
      typeof props.sourceUrl !== 'string'
    ) {
      return {
        sourceUrl: placeholder,
        sortedSrcSet: [placeholder]
      };
    }

    if (current_state.sourceUrl !== props.sourceUrl) {
      const { srcSet, size } = props;
      const sortedUrlSources = getSortedSrcSet(srcSet);

      let selectedImage = getClosestValue(sortedUrlSources, size);

      if (selectedImage) {
        return {
          sourceUrl: selectedImage[0],
          sortedSrcSet: sortedUrlSources
        };
      }
      selectedImage = sortedUrlSources[0];
      return {
        sourceUrl: selectedImage[0],
        sortedSrcSet: sortedUrlSources
      };
    }
    return null;
  }

  render() {
    const { altText, style = {} } = this.props;
    const { sourceUrl } = this.state;
    return <img loading="lazy" alt={altText} src={sourceUrl} style={style} />;
  }
}

export default ResizedImage;
