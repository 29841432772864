import styled from 'styled-components';

export const CollapsibleButton = styled.button`
  width: 100%;
  padding: 12px 32px;
  /* margin-top: 16px; */
  background: ${(props) => props.theme.colors.gray![100]};
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

interface ElementContainerProps {
  height: string;
}

export const ElementContainer = styled.div<ElementContainerProps>`
  background: ${(props) => props.theme.colors.gray![100]};
  padding: 0px 0px ${(props) => props.height} 0px;
  transition: padding 450ms ease;
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;

  /* &:first-of-type {
    border-top: 1px solid black;
  } */
`;

interface ArrowImageProps {
  isVisible: boolean;
}

export const Arrow = styled.img<ArrowImageProps>`
  margin-left: 13px;
  transition: transform 450ms;
  transform: ${(props) =>
    props.isVisible ? 'rotate(180deg)' : 'rotate(0deg)'};
`;
