import { ErrorMessage } from 'formik';
import React from 'react';

import * as S from './styles';

interface TextareaInputProps {
  name: string;
  isInvalid: boolean;
  placeholder: string;
}

const TextareaInput: React.FC<TextareaInputProps> = ({
  name,
  isInvalid,
  placeholder
}) => (
  <S.InputContainer>
    <S.StyledTextarea
      component="textarea"
      name={name}
      isInvalid={isInvalid}
      placeholder={placeholder}
    />
    {isInvalid && (
      <S.ErrorContainer>
        <ErrorMessage name={name} />
      </S.ErrorContainer>
    )}
  </S.InputContainer>
);

export default TextareaInput;
